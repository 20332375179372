import React, { useEffect } from 'react';

import { Helmet } from '../../components/Helmet';

import { BookingLayout } from '../../containers/BookingJourney';
import { LandingMain } from '../../containers/BookingJourney/components/landing';
import { BASE_URL } from '../../constants';
import { useFiltersStore } from '../../containers/BookingJourney/context';
import { useSegmentPage } from '../../hooks/useSegmentPage';

import HeroImgMobile from '../../containers/BookingJourney/assets/Walking_Mobile.png';
import HeroImgDesktop from '../../containers/BookingJourney/assets/Walking_Desktop.png';
import { howToBookWalkingItems } from '../../containers/BookingJourney/components/landing/how-to-book/data';
import { dogWalkFAQData } from '../../data/dog-walk-faq-data';
import { dogWalkingBenefits } from '../../containers/BookingJourney/components/landing/service-benefits/data/dog-walking-benefits';
import { useSetIsOnDemand } from '../../hooks';
import { tryCatch } from '../../utils';
import { getServicePageResponse } from '../../hooks/api/getServicePageData';
import { ServiceLandingPageProps } from '../../types/app/service-landing-page';

const meta = {
  title: 'On-Demand Dog Walking | Wag!',
  description: 'Wag! is the #1 rated, most affordable, trusted dog walking app, with over 10 million dog walking services provided. Book a scheduled or on-demand dog walk today with a local walker near you.',
};

const DogWalking = ({
  modal
}: ServiceLandingPageProps) => {
  const canonicalUrl = `${BASE_URL}/dog-walking`;

  const setFilters = useFiltersStore((state) => state.setFilters);

  useEffect(() => {
    setFilters({ service: 'walk' });
  }, [setFilters]);

  useSetIsOnDemand(true);

  const {
    execute: executeSegmentPage,
  } = useSegmentPage();
  useEffect(() => {
    executeSegmentPage({
      name: 'Booking Journey - Dog Walking Landing',
    });
  }, [executeSegmentPage]);
  return (
    <>
      <Helmet
        canonical={canonicalUrl}
        title={meta.title}
        description={meta.description}
        robots="index, follow"
        openGraph={{
          description: meta.description,
          title: meta.title,
          siteName: 'WagWalking',
          type: 'website',
          url: canonicalUrl,
        }}
        twitter={{
          title: meta.title,
          description: meta.description,
          site: '@WagWalking',
        }}
      />
      <LandingMain
        {...modal && {
          promoModalProps: {
            headerText: modal.title,
            subHeaderText: modal.caption,
            promoCode: modal.promoCode,
            offerExpiry: modal.expiration,
            msBeforeShow: modal.msBeforeShow,
          }
        }}
        heroProps={{
          heroMobileImg: HeroImgMobile,
          heroDesktopImg: HeroImgDesktop,
          taglineLink: '/drop-ins',
          tagline: 'Drop-In',
          taglineText: 'Do you need a Caregiver to look after your pet? ',
          heroTitleProps: {
            title: 'Local, trusted dog walkers near you',
            subtitle: 'Book trusted, local, 5-star dog walkers in your neighborhood',
          },
        }}
        wagStates={{
          title: `Trusted pet walkers near you`,
        }}
        howToBookProps={{
          howToBookTitle: 'How to book a Wag! Walk with us',
          items: howToBookWalkingItems,
        }}
        descriptionProps={{
          descriptionTitle: 'What makes Dog Walking with Wag! so great?',
        }}
        localExpertProps={{
          serviceType: 'Walking',
          localExpertTitle: 'Dog walks with local experts you can trust',
        }}
        faqProps={{
          items: dogWalkFAQData,
        }}
        serviceDescription={{
          title: 'Trusted local dog walkers',
          description: 'Stuck at work? Dog walkers on Wag! are available to stop by as many times as you need to give your pup a potty break and exercise. Every walker has passed an enhanced background check, all services are insured, and support is available 7 days a week.',
        }}
        serviceBenefits={{
          items: dogWalkingBenefits,
        }}
      />
    </>
  );
};

DogWalking.Layout = BookingLayout;

export const getStaticProps = async () => {
  const slug = 'dog-walking';
  const [servicePageResponse,] = await tryCatch(() => getServicePageResponse(slug));

  return {
    props: {
      tailwind: true,
      ...servicePageResponse?.modal && { modal: servicePageResponse.modal },
      
    },
    revalidate: 10, // Revalidate every 10 seconds
  };
};


export default DogWalking;
