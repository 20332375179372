import React from 'react';

import { ServiceBenefitsProps } from '..';

import BenefitWalkImg from '../../../../assets/benefit-walk.png';
import BenefitStimulateImg from '../../../../assets/benefit-stimulate.png';
import BenefitObesityImg from '../../../../assets/benefit-obesity.png';

export const dogWalkingBenefits: ServiceBenefitsProps['items'] = [
  {
    title: 'Benefits of daily dog walks',
    Description: () => (
      <p>
        You might be asking yourself, “How often should I walk my dog?”.
        According to our guide on
        {' '}
        <a
          href="https://wagwalking.com/daily/how-often-should-you-walk-your-dog"
          className="text-wagBlue underline"
        >
          how often you should walk your dog
        </a>
        {' '}
        a
        minimum of two 15-minute walks to potty and exercise are recommended for most dogs.
        Many will need one or two more during the day.
        <br />
        <br />

        Also, spending quality time walking your dog
        is SO rewarding that some pet parents might wonder,
        {' '}
        <a
          href="https://wagwalking.com/daily/does-walking-my-dog-really-count-as-exercise"
          className="text-wagBlue underline"
        >
          &quot;Is walking your dog really exercise?&quot;
        </a>

        <br />
        <br />

        Short answer: Yes! Walking your dog every day is a great way to stay in shape.
        Daily 30-minute walks reduce the risk of heart disease and diabetes,
        prevent inflammation and
        {' '}
        <a
          href="https://wagwalking.com/daily/does-walking-my-dog-really-count-as-exercise"
          className="text-wagBlue underline"
        >
          stiff joints.
        </a>
      </p>
    ),
    image: BenefitWalkImg,
  },
  {
    title: "Stimulate your pup's mind",
    Description: () => (
      <p>
        Bored dogs are often destructive dogs.
        When they don&apos;t get enough stimulation,
        even trained dogs might resort to gnawing on furniture,
        peeing on your shoes, or digging up the garden.
        Whether you have a
        {' '}
        <a
          href="https://wagwalking.com/breed"
          className="text-wagBlue underline"
        >
          Greyhound
        </a>
        {' '}
        or a
        {' '}
        <a
          href="https://wagwalking.com/breed/pug"
          className="text-wagBlue underline"
        >
          Pug,
        </a>
        a daily 30-minute walk will offer up all manner of new sights,
        smells, and experiences for your pup to soak up.
      </p>
    ),
    image: BenefitObesityImg,
  },
  {
    title: 'Keep the pounds off',
    Description: () => (
      <p>
        Let&apos;s face it — we all love treating our good boys and girls
        to a tasty table scrap now and then.
        That&apos;s precisely why walking your dog daily is essential.
        {' '}
        {' '}
        <a
          href="https://wagwalking.com/condition/obesity"
          className="text-wagBlue underline"
        >
          Obesity in dogs
        </a>
        {' '}
        is linked to an array of illnesses,
        including
        {' '}
        <a
          href="https://wagwalking.com/care/conditions"
          className="text-wagBlue underline"
        >
          heart disease
        </a>
        , cancer, and
        {' '}
        <a
          href="https://wagwalking.com/condition/diabetes"
          className="text-wagBlue underline"
        >
          diabetes.
        </a>
        {' '}
        Both pups and people who exercise regularly live longer on
        average than those who don&apos;t.
        Daily walks ensure your dog&apos;s life is as long, healthy, and happy as possible!
      </p>
    ),
    image: BenefitStimulateImg,
  },
];
