export const dogWalkFAQData = [
  {
    question: 'Dog Walking Safety',
    answer: [
      "If you're new to dog walking services, you probably have a ton of questions about how hiring a dog walker works and what you can expect. We've answered some of the most common questions about on-demand dog walking.",
    ].join('\n'),
    highlight: false,
  },
  {
    question: 'Is hiring a dog walker safe?',
    answer:
      `As fellow Pet Parents and animal lovers ourselves, we understand that you won't trust just anyone to care for your fur-baby. That's why we've implemented state-of-the-art safety features for your peace of mind. GPS tracking, in-app messaging, and photo and video updates allow pet parents to follow along their dog's walk in real-time right on their smartphones.
      <br />
      <br />
      All of the Pet Caregivers on our platform must pass a thorough background check and dog walking proficiency test that assesses their knowledge of leash, harness, and collar best practices. Pet Caregivers must also demonstrate their ability to handle and care for dogs in real-life scenarios.
      `
    ,
  },
  {
    question: 'How can I tell if a dog walker is trustworthy?',
    answer: [
      'Before any walker begins working with Wag!, they must first undergo a thorough vetting process, which includes dog handling safety tests, phone screening, and background checks. Our top priority is ensuring Pet Parents feel safe entrusting their dogs\' care to the dog walkers and caregivers on the Wag! platform.',
      'When it comes to selecting a dog walker, our algorithm is designed to choose your pup\'s "pawfect" match. However, we encourage Pet Parents to take their time choosing a dog walker. Read each Pet Caregiver\'s profile and reviews to learn more about their pet care experience. Wag! Pet Caregiver profiles include:',
      '- a summary of the Pet Caregiver\'s experience working with dogs',
      '- their rates and services offered',
      '- areas of expertise (running with dogs, walking dogs who pull, senior dog care, etc.)',
      '- verified reviews from other Pet Parents who booked services with the Pet Caregiver',
      '- a list of achievements (number of walks completed, breeds they work with most often, endorsements from Pet Parents, etc.)',
      'When you schedule a dog walking service through Wag!, you can chat with your Pet Caregiver before, during, and after the walk. Feel free to ask as many questions as you like at any time once you\'ve scheduled your service. We also invite Pet Caregivers to ask Pet Parents questions about their preferences and their dog\'s unique needs.',
    ].join('\n'),
  },
  {
    question: 'How many dogs can a dog walker handle at once?',
    answer:
      'Dog walkers on the Wag! platform are only allowed to walk the dogs from one household at a time. Our app doesn\'t allow Pet Caregivers to accept two services scheduled for the same time and date.',
  },
  {
    question: 'How do dog walkers get in the house?',
    answer:
      'How your walker accesses your home is entirely up to you. You can choose to have someone answer the door if you\'d like. A Wag! Lockbox also offers extra peace of mind. When booking a service, enter your preferences on the \'How to Get In\' screen.',
  },
  {
    question: 'Average cost of dog walking in the United States',
    answer: [
      'The cost of dog walking in the U.S. is based on the price set by the Pet Caregiver and varies depending on a variety of factors, including where you live, the length of the walk, and any additional fees that may apply.',
      '<br />',
      '<br />',
      '<span>*Average Cost Per Walk For One Dog –</span>',
      '<br />',
      '<br />',
      '<strong> 20 min - $12.75 - $19.99 </strong>',
      '<br />',
      '<strong> 30 min - $17.00 - $26.99 </strong>',
      '<br />',
      '<strong> 60 min - $25.50 - $36.99 </strong>',
      '<br />',
      '<br />',
      'The estimated rate for your service will be displayed at the bottom of your screen during booking, and a final price will be provided when you match with a Pet Caregiver.',
      '<br />',
      '<br />',
      '<span style="font-size:13px;">*These are estimated prices. The final price may be higher or lower. </span>',
    ].join('\n'),
    highlight: false,
  },
  {
    question: 'Should dog walkers be tipped?',
    answer:
      'Tipping your dog walker isn\'t required, but always appreciated! Tips are a great way to show your gratitude for a job well done.',
  },
  {
    question: 'Do dog walkers on the Wag! platform get to keep their tips?',
    answer: 'Yes! Dog walkers through Wag! keep 100% of their tips.',
  },
  {
    question: 'How much do dog walkers make on average?',
    answer:
      'That depends on the rates each dog walker sets up, how many walks they complete each week, and the costs of living in their area. Some of the walkers on our platform make a full-time income walking dogs!',
  },
];
